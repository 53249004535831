var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({ '--header-bg-color': _vm.color })},[(_vm.$slots.top)?[_c('div',{staticClass:"text-right mb-4"},[_vm._t("top")],2)]:_vm._e(),_c('v-data-table',_vm._b({staticClass:"tt-table elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.computedHeaders,"fixed-header":_vm.fixedHeader,"height":_vm.height,"items":_vm.rows,"disable-sort":!_vm.computedSortable,"show-expand":_vm.showExpansionColumn,"footer-props":{
      'items-per-page-options': _vm.computedItemsPerPageOptions,
    },"hide-default-footer":_vm.computedHideDefaultFooter,"show-select":_vm.showSelect,"value":_vm.value},on:{"pagination":_vm.handlePagination,"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"item",fn:function({ item, index }){return [_c('tr',{class:_vm.getRowClass(index)},[(_vm.showSelect)?_c('td',[_c('v-checkbox',{attrs:{"input-value":_vm.isRowSelected(item)},on:{"click":function($event){$event.stopPropagation();return _vm.toggleSelection(item)}}})],1):_vm._e(),_vm._l((_vm.headers),function(header){return _c('td',{key:header.text,class:{
            'text-right': header.align === 'end',
            grey: header.disabled,
            'lighten-3': header.disabled,
            'grey--text': header.disabled,
            'text--lighten-1': header.disabled,
          }},[_vm._t('column_' + header.value,function(){return [(header.type === 'chips' && header.render)?[_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":_vm.chipValue({ header, item, type: 'color' }),"small":""}},[_vm._v(" "+_vm._s(_vm.chipValue({ header, item, type: 'text' }))+" ")])]:_vm._e(),(header.type === 'expansion')?[(item.showExpansion)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.toggleExpanded(item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.expanded[item.id] ? `mdi-chevron-up` : `mdi-chevron-down`))])],1):_vm._e()]:[(header.click)?_c('v-btn',{staticClass:"font-weight-bold",attrs:{"text":"","small":"","color":"primary"},on:{"click":function($event){return header.click(item)}}},[_vm._v(_vm._s(_vm.getRowText({ header, item })))]):(header.disabled && !_vm.showDisabled)?_c('span'):_c('span',{class:_vm.getClasses({ header, item })},[_vm._v(" "+_vm._s(_vm.getRowText({ header, item }))+" ")])]]},{"header":header,"item":item})],2)}),(_vm.menuOptions.length > 0)?_c('td',{staticClass:"text-right"},[_c('v-menu',{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.menuOptions),function(option,index){return _c('v-list-item',{key:index,attrs:{"disabled":option.disabled ? option.disabled(item) : false},on:{"click":function($event){return option.click(item)}}},[(option.icon)?_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(option.icon))]):_vm._e(),_c('v-list-item-title',[_vm._v(_vm._s(option.text))])],1)}),1)],1)],1):_vm._e()],2),(_vm.expanded[item.id])?_c('tr',[_c('td',{staticClass:"px-0",attrs:{"colspan":_vm.headers.length + 1}},[_vm._t("expansion",null,{"item":item})],2)]):_vm._e()]}},(_vm.$slots.footer)?{key:"foot",fn:function(){return [_vm._t("footer")]},proxy:true}:null],null,true)},'v-data-table',_vm.paginationProps,false))],2)
}
var staticRenderFns = []

export { render, staticRenderFns }